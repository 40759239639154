import { render, staticRenderFns } from "./ServiceRates.vue?vue&type=template&id=0201d227&scoped=true&"
import script from "./ServiceRates.vue?vue&type=script&lang=js&"
export * from "./ServiceRates.vue?vue&type=script&lang=js&"
import style0 from "./ServiceRates.vue?vue&type=style&index=0&id=0201d227&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0201d227",
  null
  
)

export default component.exports