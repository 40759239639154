import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class ServiceRatesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/service-rates', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/service-rates/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/service-rates', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/service-rates/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/service-rates/${id}`)
  }
}

export default ServiceRatesProvider
